<template>
  <z-card-modal
    :title="$t('modal.sent_terms_modal.title')"
    :opened="opened"
    persistent
    width="430px"
  >
    <template #content>
      <div class="body-modal-container">
        <z-icon color="var(--lightgreen-600)" size="100px" class="mt-3 mb-3">
          $check_circle_outline_rounded
        </z-icon>
        <p class="desktop-text body-text text-center">
          {{ $t("modal.sent_terms_modal.description") }}
        </p>
      </div>
      <div class="btn-container">
        <z-btn
          :text="$t('reservation.btn_ok')"
          primary
          @click="$emit('click:close-sent-modal')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.body-modal-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.body-modal-container img {
  margin: 15px 0;
  width: 100px;
}

.btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn-container button {
  width: 100%;
  margin-top: 2em;
}
</style>
