<template>
  <z-card-modal
    persistent
    :opened="opened"
    width="740px"
    @change="$emit('close')"
  >
    <template #content>
      <div class="d-flex flex-column mt-3 mb-5 align-center">
        <section
          class="main-content-modal p-5"
          style="max-height: 400px; overflow-y: auto; overflow-x: hidden"
        >
          <div class="text-content">
            <div class="px-2 content" v-html="terms.terms"></div>
          </div>
        </section>

        <z-btn
          data-cy="understand-btn"
          class="understand-btn mt-5"
          primary
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '50%'"
          :text="$t('modal.privacy_policy_modal.ok_btn')"
          @click="$emit('close')"
        />

        <z-btn
          class="send-terms-mail mt-5"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '50%'"
          :text="$t('modal.privacy_policy_modal.button_send')"
          @click="$emit('click:send_terms', 'privacy')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    terms: {
      type: Object,
      default: () => {},
    },
  },
  setup() {},
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .content {
    text-align: justify;
    max-width: 90%;
    margin: 0 auto;
    :nth-child(2) {
      margin: 0 !important;
      padding: 0 !important;
      margin: 0 auto !important;
      max-width: 90%;
    }
    :nth-child(3) {
      margin-top: 20px !important;
    }
  }
}
</style>
