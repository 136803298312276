<template>
  <z-section-divider :title="$t('guest.terms')" start-opened>
    <template #content>
      <z-checkbox
        data-cy="terms-checkbox"
        color="primary"
        hide-details
        :value="guest.terms[0].isChecked && guest.terms[1].isChecked"
        @change="
          $emit('change', {
            'terms[0].isChecked': $event,
            'terms[0].id': terms.use.id,
            'terms[1].isChecked': $event,
            'terms[1].id': terms.privacy.id,
          })
        "
      >
        <template #label>
          <i18n
            path="guest.i_agree_with_terms"
            tag="label"
            for="guest.i_agree_with_terms"
          >
            <template #terms>
              <a href="#" target="_blank" @click="$emit('click:terms')">
                {{ $t("guest.terms") }}
              </a>
            </template>
            <template #privacy>
              <a href="#" target="_blank" @click="$emit('click:privacy')">
                {{ $t("guest.privacy_policy") }}
              </a>
            </template>
          </i18n>
        </template>
      </z-checkbox>

      <z-checkbox
        color="primary"
        hide-details
        :value="guest.optins[1].isChecked"
        @change="$emit('change', { 'optins[1].isChecked': $event })"
      >
        <template #label>
          {{
            $t("guest.i_agree_zoox_communication", {
              hotel: hotel.nomeFantasia,
            })
          }}
        </template>
      </z-checkbox>
    </template>
  </z-section-divider>
</template>

<script>
export default {
  props: {
    guest: {
      type: Object,
      required: true,
    },
    hotel: {
      type: Object,
      required: true,
    },
    terms: {
      type: Object,
      required: true,
    },
  },
}
</script>
