<template>
  <z-card
    v-if="arrivingFromData.length > 0 && nextDestionationData.length > 0"
    width="100%"
  >
    <template #content>
      <div
        v-if="arrivingFromData.length > 0"
        class="section-subheader mt-0"
        style="color: var(--black-600)"
      >
        {{ $t("guest.origin") }}
      </div>
      <template v-for="(item, index) of arrivingFromData">
        <confirmation-item
          v-if="item.show"
          :key="'arriving' + index"
          :label="item.label"
          :value="item.value"
        />
      </template>

      <div
        v-if="nextDestionationData.length > 0"
        class="section-subheader"
        style="color: var(--black-600)"
      >
        {{ $t("guest.destiny") }}
      </div>
      <template v-for="(item, index) of nextDestionationData">
        <confirmation-item
          v-if="item.show"
          :key="'destination' + index"
          :label="item.label"
          :value="item.value"
        />
      </template>

      <template v-if="config.travelData?.transportType?.default === ''">
        <div class="section-subheader" style="color: var(--black-600)">
          {{ $t("guest.transport_type") }}
        </div>
        <confirmation-item
          :label="$t('reservation.transport_type')"
          :value="guest.transportTypeName"
        />
      </template>

      <template v-if="config.travelData?.purposeTrip?.default === ''">
        <div class="section-subheader" style="color: var(--black-600)">
          {{ $t("guest.travel_reason") }}
        </div>
        <confirmation-item
          :label="$t('reservation.travel_purpose')"
          :value="guest.purposeName"
        />
      </template>

      <z-btn
        class="mt-4"
        width="100%"
        :text="$t('guest.edit_information')"
        @click="$emit('click')"
      />
    </template>
  </z-card>
</template>

<script>
import { computed, toRefs } from "vue"

import i18n from "@/i18n"

import ConfirmationItem from "./Item.vue"

export default {
  components: { ConfirmationItem },
  props: {
    guest: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { guest } = toRefs(props)

    const arrivingFromData = computed(() => {
      return [
        {
          label: i18n.tc("reservation.country"),
          value: guest.value.arrivingFromCountryName,
          show: !props.config.travelData.arrivingFromCountry.default,
        },
        {
          label: i18n.tc("reservation.state"),
          value: guest.value.arrivingFromStateName,
          show: !props.config.travelData.arrivingFromState.default,
        },
        {
          label: i18n.tc("reservation.city"),
          value: guest.value.arrivingFromName,
          show: !props.config.travelData.arrivingFromCity.default,
        },
      ].filter((item) => item.show)
    })

    const nextDestionationData = computed(() => {
      return [
        {
          label: i18n.tc("reservation.country"),
          value: guest.value.nextDestinationCountryName,
          show: !props.config.travelData.nextDestinationCountry.default,
        },
        {
          label: i18n.tc("reservation.state"),
          value: guest.value.nextDestinationStateName,
          show: !props.config.travelData.nextDestinationState.default,
        },
        {
          label: i18n.tc("reservation.city"),
          value: guest.value.nextDestinationName,
          show: !props.config.travelData.nextDestinationCity.default,
        },
      ].filter((item) => item.show)
    })

    return {
      arrivingFromData,
      nextDestionationData,
    }
  },
}
</script>

<style lang="scss" scoped>
.section-subheader {
  color: var(--black-600);
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: var(--zoox-font-size-body3);
}
</style>
