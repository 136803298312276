import { render, staticRenderFns } from "./PrivacyPolicyModal.vue?vue&type=template&id=a0075076&scoped=true&"
import script from "./PrivacyPolicyModal.vue?vue&type=script&lang=js&"
export * from "./PrivacyPolicyModal.vue?vue&type=script&lang=js&"
import style0 from "./PrivacyPolicyModal.vue?vue&type=style&index=0&id=a0075076&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0075076",
  null
  
)

export default component.exports