<template>
  <z-card width="100%">
    <template #title>
      <h3>{{ $t("guest.data_confirmation") }}</h3>
    </template>

    <template #actions>
      <z-btn
        small
        rounded
        primary
        :text="$t('guest.edit_data')"
        :disabled="!disabled"
        @click="$emit('edit')"
      />
    </template>

    <template #content>
      <div class="confirmation-content">
        <div style="text-align: center">
          <guest-avatar
            hide-edit
            :photo="guest.photoUrlMemory || guest.photoUrl"
            size="120"
            @click:upload="$emit('click:upload')"
          />
        </div>
        <div>
          <personal-data-section
            :guest="guest"
            :config="personalInfoConfig"
            :countries="countries"
            :jobs="jobs"
            :disabled="disabled"
            :v$="validations"
            :get-error-messages="getErrorMessages"
            @change="$emit('change', $event)"
          />
          <documents-section
            :guest="guest"
            :config="personalInfoConfig"
            :disable-document-upload="disableDocumentUpload"
            :v$="validations"
            :get-error-messages="getErrorMessages"
            :disabled="disabled"
            @change="$emit('change', $event)"
          />
          <contact-section
            :guest="guest"
            :config="personalInfoConfig"
            :disabled="disabled"
            :v$="validations"
            :get-error-messages="getErrorMessages"
            @change="$emit('change', $event)"
          />

          <address-section
            :guest="guest"
            :config="personalInfoConfig"
            :cities="citiesOptions.residence"
            :states="statesOptions.residence"
            :disabled="disabled"
            :v$="validations"
            :get-error-messages="getErrorMessages"
            @change="$emit('change', $event)"
          />

          <travel-data-section
            :guest="guest"
            :config="additionalDataConfig"
            :cities-options="citiesOptions"
            :states-options="statesOptions"
            :transport-types="transportTypes"
            :countries="countries"
            :purposes="purposes"
            :disabled="disabled"
            :v$="validations"
            :get-error-messages="getErrorMessages"
            @change="$emit('change', $event)"
          />

          <extra-data-section
            :guest="guest"
            :config="additionalDataConfig"
            :v$="validations"
            :disabled="disabled"
            :get-error-messages="getErrorMessages"
            @change="$emit('change', $event)"
          />
          <terms-section
            :guest="guest"
            :hotel="hotel"
            :terms="terms"
            @click:terms="$emit('click:terms')"
            @click:privacy="$emit('click:privacy')"
            @change="$emit('change', $event)"
          />

          <div class="actions-container">
            <template v-if="disabled">
              <z-btn
                :text="$t('guest.back')"
                :disabled="isLoadingNext"
                @click="$emit('back')"
              />
              <z-btn
                data-cy="confirmation-save-btn"
                primary
                :text="$t('guest.confirm_information')"
                :disabled="
                  !guest.terms[0].isChecked || !guest.terms[1].isChecked
                "
                :is-loading="isLoadingNext"
                @click="$emit('submit')"
              />
            </template>
            <template v-else>
              <z-btn
                :text="$t('guest.cancel')"
                :disabled="isLoading"
                @click="$emit('cancel')"
              />
              <z-btn
                primary
                :text="$t('guest.save_data')"
                :is-loading="isLoading"
                @click="onClickSave"
              />
            </template>
          </div>
        </div>
      </div>
    </template>
  </z-card>
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import { computed, onMounted, toRefs } from "vue"

import ExtraDataSection from "../../AdditionalData/components/sections/ExtraDataSection.vue"
import TravelDataSection from "../../AdditionalData/components/sections/TravelDataSection.vue"
import { useAdditionalInfoValidations } from "../../AdditionalData/components/useValidations"
import AddressSection from "../../PersonalInfo/components/sections/AddressSection.vue"
import ContactSection from "../../PersonalInfo/components/sections/ContactSection.vue"
import DocumentsSection from "../../PersonalInfo/components/sections/DocumentsSection.vue"
import PersonalDataSection from "../../PersonalInfo/components/sections/PersonalDataSection.vue"
import { usePersonalInfoValidations } from "../../PersonalInfo/components/useValidations"
import TermsSection from "./sections/TermsSection.vue"

export default {
  components: {
    AddressSection,
    ContactSection,
    DocumentsSection,
    PersonalDataSection,
    TravelDataSection,
    ExtraDataSection,
    TermsSection,
  },
  props: {
    guest: {
      type: Object,
      required: true,
    },
    hotel: {
      type: Object,
      required: true,
    },
    terms: {
      type: Object,
      required: true,
    },
    personalInfoConfig: {
      type: Object,
      required: true,
    },
    additionalDataConfig: {
      type: Object,
      required: true,
    },
    jobs: {
      type: Array,
      default: () => [],
    },
    citiesOptions: {
      type: [Array, Object],
      default: () => ({ origin: [], destiny: [], residence: [] }),
    },
    statesOptions: {
      type: Object,
      default: () => ({ origin: [], destiny: [], residence: [] }),
    },
    transportTypes: {
      type: Array,
      default: () => [],
    },
    purposes: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingNext: {
      type: Boolean,
      default: false,
    },
    disableDocumentUpload: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { guest } = toRefs(props)
    const { getErrorMessages, personalDataRules, contactRules, addressRules } =
      usePersonalInfoValidations({
        config: props.personalInfoConfig,
        guest,
      })

    const { travelDataRules, extraDataRules } = useAdditionalInfoValidations({
      config: props.additionalDataConfig,
      guest,
    })

    const rules = computed(() => {
      return {
        ...personalDataRules.value,
        ...contactRules.value,
        ...addressRules.value,
        ...extraDataRules.value,
        ...travelDataRules.value,
      }
    })

    const validations = useVuelidate(rules, guest)

    const onClickSave = () => {
      validations.value.$touch()

      if (!validations.value.$invalid) {
        emit("save")
      }
    }

    onMounted(() => {
      validations.value.$reset()
    })

    return {
      rules,
      validations,
      getErrorMessages,
      onClickSave,
    }
  },
}
</script>

<style lang="scss" scoped>
.confirmation-content {
  display: grid;
  margin-top: 20px;
  column-gap: 20px;
  grid-template-columns: min-content 1fr;
}

.actions-container {
  display: grid;
  width: 100%;
  grid-template-columns: min-content min-content;
  justify-content: end;
  column-gap: 10px;
  margin-top: 15px;
}
</style>
