<template>
  <z-card class="personal-info-card" width="100%">
    <template #content>
      <p class="body-text">{{ $t("guest.confirm_your_data") }}</p>
      <div class="section-subheader mt-0">
        {{ $t("guest.personal_information") }}
      </div>
      <z-avatar
        class="avatar-photo"
        size="128"
        :photo="guest.photoUrlMemory || guest.photoUrl"
      />
      <div>
        <template v-for="(item, index) of guestData">
          <confirmation-item
            v-if="item.show"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </template>
      </div>

      <z-btn
        width="100%"
        :text="$t('guest.edit_information')"
        @click="$emit('click')"
      />
    </template>
  </z-card>
</template>

<script>
import find from "lodash.find"
import { computed, toRefs } from "vue"

import i18n from "@/i18n"

import ConfirmationItem from "./Item.vue"

export default {
  components: { ConfirmationItem },
  props: {
    guest: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    documentTypes: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { guest } = toRefs(props)

    const guestData = computed(() => {
      return [
        { label: i18n.tc("guest.name"), value: guest.value.name, show: true },
        {
          label: i18n.tc("guest.last_name"),
          value: guest.value.lastName,
          show: true,
        },
        { label: i18n.tc("guest.email"), value: guest.value.email, show: true },
        {
          label: i18n.tc("guest.document_type"),
          value: find(props.documentTypes, {
            value: guest.value.documents.documentTypeId,
          })?.text,
          show: true,
        },
        {
          label: i18n.tc("guest.document_number"),
          value: guest.value.documents.documentNumber,
          show: true,
        },
        {
          label: i18n.tc("guest.document_additional"),
          value: guest.value.additionalDocument.documentNumber,
          show: !props.config.documentData?.additionalDocument?.rules?.hidden,
        },
        {
          label: i18n.tc("guest.gender"),
          value: guest.value.gender,
          show: props.config.personalData.gender.default === "",
        },
        {
          label: i18n.tc("guest.nationality"),
          value: guest.value.nationalityName,
          show: props.config.personalData.nationalityCountry.default === "",
        },
        {
          label: i18n.tc("guest.residency_country"),
          value: guest.value.countryName,
          show: props.config.personalData.residenceCountry.default === "",
        },
        {
          label: i18n.tc("guest.profession"),
          value: guest.value.jobName,
          show: props.config.personalData.job.default === "",
        },
        {
          label: i18n.tc("guest.mobile_phone"),
          value: guest.value.mobilePhoneNumber,
          show: true,
        },
        {
          label: i18n.tc("guest.state"),
          value: guest.value.stateName,
          show: props.config.addressData.state.default === "",
        },
        {
          label: i18n.tc("guest.city"),
          value: guest.value.cityName,
          show: props.config.addressData.city.default === "",
        },
        {
          label: i18n.tc("guest.neighborhood"),
          value: guest.value.neighborhood,
          show: props.config.addressData.neighborhood.default === "",
        },
        {
          label: i18n.tc("guest.address"),
          value: guest.value.address,
          show: props.config.addressData.address.default === "",
        },
        {
          label: i18n.tc("guest.address_number"),
          value: guest.value.addressNumber,
          show: props.config.addressData.addressNumber.default === "",
        },
        {
          label: i18n.tc("guest.address_complement"),
          value: guest.value.addressComplement,
          show: props.config.addressData.addressComplement.default === "",
        },
      ]
    })

    return {
      guestData,
    }
  },
}
</script>

<style lang="scss" scoped>
.personal-info-card {
  display: grid;
  row-gap: 15px;
}

.avatar-photo {
  justify-self: center;
}
.section-subheader {
  color: var(--black-600);
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: var(--zoox-font-size-body3);
}
</style>
