<template>
  <div class="confirmation-item">
    <div class="body-bold">{{ label }}</div>
    <div class="body-text">{{ value || "-" }}</div>
    <v-divider class="grid-span-2"></v-divider>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "-",
    },
  },
}
</script>

<style lang="scss" scoped>
.confirmation-item {
  display: grid;
  align-items: center;
  column-gap: 10px;
  grid-template-columns: 40% 60%;
  padding-top: 12px;
  row-gap: 12px;
}
</style>
