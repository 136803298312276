<template>
  <div data-cy="confirmation-data-page" class="confirmation-main-container">
    <component
      :is="
        $vuetify.breakpoint.smAndDown
          ? 'confirmation-data-mobile'
          : 'confirmation-card'
      "
      :disabled="!isEditing"
      :document-types="documentTypes"
      :hotel="hotel"
      :terms="terms"
      :jobs="jobs"
      :purposes="purposes"
      :transport-types="transportTypes"
      :guest="guest"
      :countries="countries"
      :personal-info-config="personalInfoConfig"
      :additional-data-config="additionalDataConfig"
      :states-options="statesOptions"
      :cities-options="citiesOptions"
      :is-loading="isLoading"
      :is-loading-next="isLoadingNext"
      :disable-document-upload="true"
      @click:terms="isModalOpened.terms = true"
      @click:privacy="isModalOpened.privacy = true"
      @cancel="isEditing = false"
      @edit="onClickEdit"
      @save="onClickSave"
      @submit="onSubmit"
      @back="$store.dispatch('flow/guest/previous')"
      @change="onChangeGuest"
      @edit:personal="$router.push({ name: 'personal-info' })"
      @edit:additional="$router.push({ name: 'additional-data' })"
    />
    <use-terms-modal
      :opened="isModalOpened.terms"
      :terms="terms.use"
      @close="isModalOpened.terms = false"
      @click:send_terms="onClickSendTerms('use')"
    />
    <privacy-policy-modal
      :opened="isModalOpened.privacy"
      :terms="terms.privacy"
      @close="isModalOpened.privacy = false"
      @click:send_terms="onClickSendTerms('privacy')"
    />
    <sent-terms-success-modal
      :opened="isModalOpened.termsSent"
      @click:close-sent-modal="isModalOpened.termsSent = false"
    />
  </div>
</template>

<script>
import find from "lodash.find"
import { onMounted, ref } from "vue"
import { useRouter } from "vue-router/composables"
import {
  useNamespacedActions,
  useNamespacedGetters,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useStoreAction } from "@/composables"
import { useTracking } from "@/composables/useTracking"
import i18n from "@/i18n"

import DataValidationModal from "../../Auth/containers/DataValidationModal.vue"
import { useChangeRegion } from "../useChangeRegion"
import ConfirmationCard from "./components/ConfirmationCard.vue"
import ConfirmationDataMobile from "./components/ConfirmationDataMobile.vue"
import PrivacyPolicyModal from "./components/PrivacyPolicyModal.vue"
import SentTermsSuccessModal from "./components/SentTermsSuccessModal.vue"
import UseTermsModal from "./components/UseTermsModal.vue"

export default {
  components: {
    ConfirmationDataMobile,
    ConfirmationCard,
    UseTermsModal,
    SentTermsSuccessModal,
    PrivacyPolicyModal,
    DataValidationModal,
  },
  // eslint-disable-next-line
  setup() {
    const router = useRouter()

    const isEditing = ref(false)
    const isModalOpened = ref({
      terms: false,
      privacy: false,
      termsSent: false,
    })
    const { currentLang } = useNamespacedState("layout", ["currentLang"])
    // Company
    const { terms, hotel } = useNamespacedState("company", ["terms", "hotel"])
    const { personalInfoConfig, additionalDataConfig } = useNamespacedGetters(
      "company",
      ["personalInfoConfig", "additionalDataConfig"]
    )

    // Parameters
    const { jobs, purposes, transportTypes, documentTypes } =
      useNamespacedState("parameters", [
        "jobs",
        "purposes",
        "transportTypes",
        "documentTypes",
      ])

    // Helpers
    const { sendNotification } = useNamespacedActions("notifications", [
      "sendNotification",
    ])

    // Guest
    const { changeGuest } = useNamespacedMutations("guest", ["changeGuest"])
    const { guest } = useNamespacedState("guest", ["guest"])
    const { sendTermsToGuest } = useStoreAction("guest", "sendTermsToGuest")
    const { saveGuest, isLoading, hasError } = useStoreAction(
      "guest",
      "saveGuest"
    )
    const { trackEvent } = useTracking()

    const {
      countries,
      citiesOptions,
      statesOptions,

      onChangeZipCode,
      onChangeCity,
      onChangeState,
      onChangeCountry,
      onChangeArrivingCity,
      onChangeArrivingCountry,
      onChangeArrivingState,
      onChangeNextCity,
      onChangeNextState,
      onChangeNextCountry,
    } = useChangeRegion()

    // Flow
    const {
      next,
      isLoading: isLoadingNext,
      hasError: hasErrorNext,
    } = useStoreAction("flow/guest", "next")

    const onChangeTransportType = ({ arrivingBy }) => {
      const transport = find(transportTypes.value, { id: arrivingBy })

      const value = transport[`name_${[currentLang.value]}`] ?? transport.name

      changeGuest({ arrivingByName: value })
    }

    const onChangePurposeTrip = ({ purposeTrip }) => {
      const purpose = find(purposes.value, { id: purposeTrip })

      const value = purpose[`name_${[currentLang.value]}`] ?? purpose.name

      changeGuest({ purposeTripName: value })
    }

    const onChangeNationality = ({ nationalityId }) => {
      const country = find(countries.value, {
        code: nationalityId,
      })

      changeGuest({ nationalityName: country?.name })
    }

    /**
     *
     */
    const onChangeGuest = (chunk) => {
      changeGuest(chunk)

      if (
        "birthdateDay" in chunk ||
        "birthdateMonth" in chunk ||
        "birthdateYear" in chunk
      ) {
        {
          changeGuest({
            birthdate: `${guest.value.birthdateYear}-${guest.value.birthdateMonth}-${guest.value.birthdateDay}`,
          })
        }
      }

      if ("nationalityId" in chunk) onChangeNationality(chunk)
      if ("purposeTrip" in chunk) onChangePurposeTrip(chunk)
      if ("arrivingBy" in chunk) onChangeTransportType(chunk)
      if ("zipCode" in chunk) onChangeZipCode(chunk)
      if ("countryId" in chunk) onChangeCountry(chunk)
      if ("stateId" in chunk) onChangeState(chunk)
      if ("cityId" in chunk) onChangeCity(chunk)
      if ("arrivingFromCountryId" in chunk) onChangeArrivingCountry(chunk)
      if ("arrivingFromStateId" in chunk) onChangeArrivingState(chunk)
      if ("arrivingFrom" in chunk) onChangeArrivingCity(chunk)
      if ("nextDestinationCountryId" in chunk) onChangeNextCountry(chunk)
      if ("nextDestinationStateId" in chunk) onChangeNextState(chunk)
      if ("nextDestination" in chunk) onChangeNextCity(chunk)
    }

    const onClickEdit = () => {
      isEditing.value = true
      trackEvent("click_edit_confirmation")
    }

    const onClickSave = async () => {
      await saveGuest()
      isEditing.value = false
      trackEvent("click_save_edit_confirmation")
    }

    const onClickSendTerms = async (terms) => {
      if (terms === "privacy") {
        isModalOpened.value.privacy = false
        isModalOpened.value.termsSent = true
        await sendTermsToGuest("privacy")
      } else {
        isModalOpened.value.terms = false
        isModalOpened.value.termsSent = true
        await sendTermsToGuest("use")
      }
    }

    const { accommodationConfig } = useNamespacedGetters("company", [
      "accommodationConfig",
    ])

    const onSubmit = async () => {
      if (guest.value.masterGuest && accommodationConfig.value.enabled) {
        router.push({ name: "accommodation" })
      } else {
        await next()
        if (hasErrorNext.value) {
          sendNotification({
            message: i18n.tc("error.unknown_error"),
            duration: 4000,
            color: "var(--red-500)",
          })
        }
      }
    }

    onMounted(() => trackEvent("step_confirmation"))

    return {
      isLoading,
      isLoadingNext,
      hasError,
      isEditing,
      isModalOpened,
      changeGuest,
      guest,
      hotel,
      terms,
      personalInfoConfig,
      additionalDataConfig,
      statesOptions,
      citiesOptions,
      jobs,
      purposes,
      transportTypes,
      countries,
      documentTypes,

      onChangeGuest,
      onSubmit,
      onClickEdit,
      onClickSave,
      onClickSendTerms,
    }
  },
}
</script>
